import React from 'react'

import Diptique from '../_presentation/Diptique'

const buyImages = [
  'kommen in rode klei met niagara glazuur te koop en verkocht door Atelier KROM',
  'te koop serviesset met borden en kopjes in spikkelklei en houten ondertasjes Atelier KROM Sara Dejonghe Johan Beckers',
  'verkocht door Atelier KROM: verpakking papieren tas met logo en visitekaartje'
]

export const Header = () => {
  return (
    <Diptique id={'buy'} images={buyImages} options={['padding']}>
      <h2>Iets bestellen?</h2>

      <p>
        Ben je fan van on ze werkwijze.
        <br />
        Word je helemaal warm van de combinatie hout &times; klei?
      </p>

      <p>
        Stuur een e-mail naar welkom&#064;atelierkrom.be en geef ons je wensen
        door. We contacteren je terug met een uniek voorstel!
      </p>

      <p>
        Alvast bedankt voor je interesse!
        <br />
        <br />
        <em>— Groetjes van Johan en Sara</em>
      </p>
    </Diptique>
  )
}

export default Header
