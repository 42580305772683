import React from 'react'

import Diptique from '../_presentation/Diptique'

const homeImages = [
  'naamkaartjes van Atelier KROM in eigen keramiek met vaasjes in spikkelklei op de achtergrond Sara Dejonghe',
  'serviesset in spikkelklei met gouden bestek door Atelier KROM Sara Dejonghe',
  'houten kruidendoosje in Japanse stijl uit walnoot Atelier KROM Johan Beckers'
]

export const Header = () => {
  return (
    <Diptique id={'header'} images={homeImages} options={['padding', 'center']}>
      <h1>
        <img src={'/logo.svg'} alt={''} />
      </h1>
      <br />
      <h2>Atelier KROM</h2>

      <br />
      <p>
        Sara Dejonghe
        <br />
        Johan Beckers
      </p>
      <p>welkom&#064;atelierkrom.be</p>
      <p>
        <a
          href={'https://www.instagram.com/atelier.krom.be/'}
          title={'@atelier.krom.be'}
          target={'_blank'}
          rel='noreferrer'
        >
          instagram
        </a>
      </p>
    </Diptique>
  )
}

export default Header
