import React, { useEffect, useState } from 'react'

import './gallery.scss'

export const Gallery = ({ images }) => {
  const [index, setIndex] = useState(0)

  const diff = Math.random() * 2000

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = index >= images.length - 1 ? 0 : index + 1
      setIndex(newIndex)
    }, 4000 + diff)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [diff, index, images.length])

  return (
    <figure className='gallery'>
      {images.map(({ alt, ...image }, i) => {
        image.className = i === index ? 'current' : ''
        return <img alt={alt} {...image} />
      })}
    </figure>
  )
}

export default Gallery
