import React from 'react'

import Gallery from '../Gallery'

import './diptique.scss'

export const Diptique = ({ id, images, options = [], children }) => {
  const map = (alt, i) => ({
    key: i,
    src: `/images/${id}-${i + 1}.jpg`,
    alt: alt
  })
  return (
    <article className='diptique' id={id}>
      <Gallery images={images.map(map)} />
      <div className={'body' + options.map(o => ' body__' + o).join(' ')}>
        {children}
      </div>
    </article>
  )
}

export default Diptique
