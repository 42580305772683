import React from 'react'

import Diptique from '../_presentation/Diptique'

const saraImages = [
  'de bodem van een biscuit in rode klei met het logo van Atelier KROM Sara Dejonghe',
  'detail van een schaal uit recup-klei met niagara glazuur van 33 cm van Atelier KROM Sara Dejonghe',
  'detail van een schaal uit recup-klei met niagara glazuur van 33 cm van Atelier KROM Sara Dejonghe'
]

const johanImages = [
  'houtdraaien: lindenhouten potje in unieke vorm met deksel met handvat in notenhout Johan Beckers Atelier KROM',
  'houtdraaien: vierkante ondertas in walnotenhout voor koffiekopjes Johan Beckers Atelier KROM',
  'ruwe grondstof Afrikaans notenhout klaar om gedraaid te worden Johan Beckers Atelier KROM'
]

export const Header = () => {
  return (
    <>
      <Diptique id={'sara'} images={saraImages} options={['padding']}>
        <h2>Sara Dejonghe</h2>

        <p>
          Na haar studies voelde Sara het verlangen om weer iets creatiefs met
          haar handen te doen. Het antwoord lag dichtbij, want als kind zat ze
          graag met haar handen in de modder en kneedde ze al vroeg met klei.
        </p>

        <p>
          Het voelt voor haar geweldig om met de handen in de klei te duiken en
          in korte tijd iets prachtigs te creëren. Het proces geeft haar het
          gevoel van verbondenheid met de natuur, alsof ze met haar handen in de
          aarde zit, terwijl ze tegelijkertijd iets bruikbaars en duurzaams
          vormgeeft.
        </p>
        <p>
          Een interessant aspect van Sara’s benadering is haar worsteling met
          het streven naar perfectie. In een wereld waar perfectie vaak wordt
          nagestreefd, omarmt Sara juist het imperfecte. Ze begrijpt de
          schoonheid van het kromme en de kracht van de aanvaarding. Voor haar
          is het niet de perfectie die telt, maar de unieke charme en
          authenticiteit die voortkomen uit het omarmen van het onvolmaakte.
        </p>
      </Diptique>

      <Diptique id={'johan'} images={johanImages} options={['padding']}>
        <h2>Johan Beckers</h2>

        <p>
          Vormgeven in drie dimensies is zijn tweede natuur. Na een opleiding
          architectuur en een hobby als cameraman, lonkte het avontuur om de
          handen uit de mouwen te steken en te gaan experimenteren met
          houtdraaien.
        </p>

        <p>
          Hoewel een houtdraaibank de beperking oplegt om enkel ronde vormen te
          produceren, levert het tegelijkertijd een uitdaging om met vorm en
          gevoel aan de slag te gaan. Er zijn geen grenzen aan de verbeelding.
        </p>

        <p>
          Hout combineren met gebakken aardewerk levert een combinatie op waar
          twee natuurlijke elementen elkaar terugvinden en de kans krijgen nieuw
          leven te brengen.
        </p>
      </Diptique>
    </>
  )
}

export default Header
