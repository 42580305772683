import React from 'react'

import Diptique from '../_presentation/Diptique'

const whatImages = [
  'johan Beckers & Sara Dejonghe van Atelier KROM bij hun verzameling keramieken borden en kommen',
  'Sara Dejonghe en Johan Beckers bestuderen een bord in rode klei terwijl ze thee drinken uit zelfgemaakte koppen van Atelier KROM',
  'detail van een schaal uit recup-klei van 33 cm van Atelier KROM'
]

export const Header = () => {
  return (
    <Diptique id={'what'} images={whatImages} options={['padding', 'center']}>
      <h2>Klei &times; Hout</h2>

      <p>
        <br />
      </p>
      <p>ondergronds &times; bovengronds</p>
      <p>draaischijf &times; draaibank</p>
      <p>bakken &times; schuren</p>
      <p>glazuur &times; olie</p>
      <p>hard &times; zacht</p>
      <p>
        <br />
      </p>
      <h3>
        <s>recht</s> &times; krom
      </h3>
    </Diptique>
  )
}

export default Header
