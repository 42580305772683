import { Header, What, Who, Buy } from './home'

import './styles/app.scss'

function App () {
  return (
    <section>
      <Header />
      <What />
      <Who />
      <Buy />
    </section>
  )
}

export default App
